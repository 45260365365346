import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"kontakti"} />
		<Helmet>
			<title>
				EduQuest
			</title>
			<meta name={"description"} content={"Ваш будиночок в горах із чанами для справжнього релаксу"} />
			<meta property={"og:title"} content={"EduQuest"} />
			<meta property={"og:description"} content={"Ваш будиночок в горах із чанами для справжнього релаксу"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<meta name={"msapplication-TileColor"} content={"#F2EFEA"} />
		</Helmet>
		<Section padding="16px 40px 16px 40px" quarkly-title="Header" position="absolute" sm-padding="16px 20px 16px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				display="flex"
				justify-content="space-between"
				width="100%"
				max-width="none"
			/>
			<Box
				empty-border-color="LightGray"
				width="30%"
				align-items="center"
				lg-width="50%"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				sm-width="70%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline2Lg">
					EduQuest
				</Text>
			</Box>
			<Box
				sm-width="30%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				align-items="center"
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="70%"
				lg-width="50%"
			>
				<Components.QuarklycommunityKitMobileSidePanel>
					<Override slot="Content" justify-content="flex-end" padding="0px 0px 0px 0px" background="none" />
					<Override
						slot="Button Text"
						md-text-transform="uppercase"
						md-color="--darkL2"
						md-letter-spacing="1px"
						md-font="--base"
					/>
					<Override slot="Button Icon" md-color="--darkL2" />
					<Box
						md-padding="20px 0px 20px 0px"
						display="flex"
						justify-content="flex-end"
						align-items="center"
						md-flex-direction="column"
					>
						<Link text-decoration-line="initial" color="--darkL2" font="--base" href="/index">
							Головна
						</Link>
						<Link
							md-margin="20px 0px 0px 0px"
							href="/pro-nas"
							text-decoration-line="initial"
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
						>
							Про нас
						</Link>
						<Link
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
							md-margin="20px 0px 0px 0px"
							href="/poslugi"
							text-decoration-line="initial"
						>
							Послуги
						</Link>
						<Link
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
							md-margin="20px 0px 0px 0px"
							href="/kontakti"
							text-decoration-line="initial"
						>
							Контакти
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" quarkly-title="HeroBlock" background="linear-gradient(0deg,rgba(249, 248, 243, 0.2) 0%,rgba(249, 248, 243, 0.6) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/262732078-1024x768.jpg?v=2024-11-26T14:27:26.265Z) center/cover repeat scroll padding-box" sm-padding="100px 20px 100px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="40px"
				max-width="none"
				width="100%"
				sm-flex-direction="column"
				min-height="80vh"
				sm-min-width="280px"
			/>
			<Text
				margin="0px 0px 0px 0"
				color="--darkL2"
				font="--headline1Lg"
				width="60%"
				lg-font="--headline1Lg"
				sm-font="--headline2Lg"
				sm-width="100%"
			>
				Телефонуйте за номером
				<br />
				0674488448 і ми вам допоможемо!
			</Text>
			<Text
				sm-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0"
				color="--darkL2"
				sm-text-align="left"
				font="--headline2Lg"
				width="40%"
				text-align="right"
				lg-font="--headline3Lg"
				sm-width="100%"
			>
				полонина Драгобрат смт, Ясіня,{" "}
				<br />
				Закарпатська область, 90630
			</Text>
		</Section>
		<Components.Footer>
			<Override slot="link4">
				0674488448
			</Override>
			<Override slot="text2">
				полонина Драгобрат смт, Ясіня,{" "}
				<br />
				Закарпатська область, 90630
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});